<template>
  <div>
    <lz-dialog
      :dialogBtns="dialogBtns"
      :title="title"
      @dialog-btn-click="handleCommonBtnCLick"
      ref="lzDialog">
      <lz-table-list
        :table="table"
        @table-btn-click="handleCommonBtnCLick"
        :list="list">
      </lz-table-list>
    </lz-dialog>
  </div>
</template>
<script>
import exportPpt from '@/mixin/exportPpt';

export default {
  mixins: [exportPpt],
  data() {
    return {
      title: '',
      list: [],
      table: [
        { name: 'ID', prop: 'id', width: '80px', align: 'center' },
        { name: '产品名称', prop: 'title', width: '200px' },
        { name: '直客档位', prop: 'price_title_name' },
        { name: '分销档位', prop: 'fx_price_name' },
        { name: '库存数量', prop: 'stock' },
        { name: '兑换数量', prop: 'exchange_count' },
        { name: '推荐指数', prop: 'recommend_count' },
        { name: '操作', prop: 'htmlButton' },
      ],
      dialogBtns: [
        { key: 'export', name: '导出PPT', type: 'primary' },
      ],
    };
  },
  created() {},
  methods: {
    init() {
      this.$nextTick(() => {
        this.$refs.lzDialog.dialogVisible = true;
        this.title = `导出PPT(${this.list.length})`;
      });
    },
    handleAddProduct(list) {
      const ids = this.list.map((r) => r.id);
      const filterList = list.filter((r) => !ids.includes(r.id));
      this.list.push(...filterList);
      this.$message.success(`已成功添加${filterList.length}个产品, 当前存在${this.list.length}个产品`);
    },
    handleCommonBtnCLick(type, element) {
      console.log(type);
      switch (type) {
        case 'add': {
          if (this.search[0].value === '') return;
          const target = this.search[0].options.find((r) => String(r.value) === this.search[0].value);
          const hasGoods = this.list.some((r) => String(r.value) === this.search[0].value);
          if (hasGoods) {
            this.$message.error('存在重复商品');
            return;
          }
          this.list.push(target);
        }
          break;
        case 'delete': {
          console.log(element);
          const index = this.list.findIndex((r) => (r.id) === element.id);
          this.list.splice(index, 1);
        }
          break;
        case 'export':
          this.handleExportPPT(this.list);
          break;
        default:
          break;
      }
    },
  },
};
</script>
