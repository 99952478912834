<template>
  <lz-dialog
    :title="title"
    :is-close-btn="false"
    ref="lzDialog">
    <lz-list
      :config="config"
      :table="table"
      :is-router="false"
      :search="search"
      ref="lzList">
    </lz-list>
  </lz-dialog>
</template>
<script>
export default {
  data() {
    return {
      title: '',
      search: [
        { name: '产品id', fieldName: 'goods_id', value: '' },
      ],
      table: [
        { name: '客户名称', prop: 'company_name' },
        { name: '销售渠道', prop: 'sale_type_name' },
        { name: '订单类型', prop: 'type_name' },
        { name: '产品线', prop: 'ticket_line_name' },
        { name: '年份', prop: 'year' },
        { name: '档位', prop: 'price_title_name' },
        { name: '数量', prop: 'ticket_num' },
      ],
      config: {
        url: '/ticket/goods',
      },
    };
  },
  methods: {
    init(id, title) {
      this.search[0].value = id;
      this.title = title;
      this.$refs.lzDialog.dialogVisible = true;
      this.$nextTick(() => {
        this.$refs.lzList.getData();
      });
    },
  },
};
</script>
