<template>
  <div>
    <lz-list
      :config="config"
      :table="table"
      :search="search"
      :searchBtn="searchBtn"
      @table-checkbox-change="handleCheckboxChange"
      :selection="($route.meta.roleBtn.export == 1)"
      :searchFomate="searchFomate"
      ref="lzList"
      table-height="97%"
      @search-btn-click="handleCommonBtnCLick"
      @table-btn-click="handleCommonBtnCLick">
      <template v-if="$route.meta.roleBtn.export == 1 && values.length > 0">
        <el-button slot="pagePosition" @click="hanldeAddPPt" style="margin-top: 5px;" type="primary" size="mini">加入PPT</el-button>
      </template>
    </lz-list>
    <preview ref="preview"></preview>
    <order-view ref="orderView"></order-view>
    <appraise-view ref="appraiseView"></appraise-view>
    <export-ppt ref="exportPpt"></export-ppt>
  </div>
</template>
<script>
import { getAccessToken } from '@/utils/auth';
import { getSearchValue } from '@/utils/search';
import config from '@/config';
import cfg from './config';
import preview from './preview.vue';
import orderView from './orderView.vue';
import appraiseView from './appraiseView.vue';
import exportPpt from './exportPpt.vue';

export default {
  mounted() {},
  components: {
    preview,
    orderView,
    appraiseView,
    exportPpt,
  },
  data() {
    return {
      search: JSON.parse(JSON.stringify(cfg.search)),
      searchBtn: [],
      values: [],
      config: {
        url: '/goods/list',
        setTableFormat: (list) => {
          list.forEach((el) => {
            el.htmlButton.push({
              name: '订单明细',
              key: 'order-view',
              type: 'primary',
            },
            {
              name: '评价明细',
              key: 'appraise-view',
              type: 'primary',
            },
            {
              name: '预览',
              key: 'view',
              type: 'primary',
            });
          });
          return list;
        },
      },
      table: [
        { name: 'ID', prop: 'id', width: '80px', align: 'center' },
        { name: '产品名称', prop: 'title', width: '200px' },
        { name: '直客档位', prop: 'price_title_name' },
        { name: '分销档位', prop: 'fx_price_name' },
        { name: '库存数量', prop: 'stock' },
        { name: '兑换数量', prop: 'exchange_count' },
        // { name: '推荐指数', prop: 'recommend_count' },
        // { name: '结算方式', prop: 'settlement_type_name' },
        // { name: '兑换数量', prop: '' },
        { name: '自备货价', func: (ops) => (ops.supplier && ops.supplier.length > 0 ? ops.supplier[0].stocking_price : '-') },
        { name: '代发价', func: (ops) => (ops.supplier && ops.supplier.length > 0 ? ops.supplier[0].shipping_price : '-') },
        { name: '商城价', func: (ops) => (ops.supplier && ops.supplier.length > 0 ? ops.supplier[0].mall_price : '-') },
        { name: '市场价', prop: 'market_price' },
        { name: '税运', func: (ops) => (ops.supplier && ops.supplier.length > 0 ? ops.supplier[0].tax_type_name : '-') },
        {
          name: '是否上架',
          prop: 'is_shelves',
          width: '100px',
          type: 'switch',
          switchChange: (row) => this.handleSwitchChange('shelves', row),
        },
        {
          name: '是否断货',
          prop: 'is_out_stock',
          width: '100px',
          type: 'switch',
          switchChange: (row) => this.handleSwitchChange('out_stock', row),
        },
        {
          name: '是否停产',
          prop: 'is_stop',
          width: '100px',
          type: 'switch',
          switchChange: (row) => this.handleSwitchChange('stop', row),
        },
        {
          name: '可发货时间',
          func: (ops) => `${ops.delivery_begin_time}-${ops.delivery_end_time}`,
          width: '200px',
        },
        // { name: '型号', prop: 'model' },
        // { name: '品牌', prop: 'brand_name' },
        // {
        //   name: '是否显示',
        //   func: (ops) => (ops.status ? '否' : '是'),
        // },
        { name: '创建时间', prop: 'create_time', width: '200px' },
        { name: '操作', prop: 'htmlButton', fixed: 'right' },
      ],
    };
  },
  created() {
    if (this.$route.meta.roleBtn.export) {
      this.searchBtn = [{ name: '导出PPT', type: 'primary', key: 'ppt' }];
    }
  },
  methods: {
    handleCommonBtnCLick(type, element) {
      const lzList = this.$refs.lzList;
      switch (type) {
        case 'delete':
          this.$baseConfirm({
            content: '是否确定删除',
            callConfirm: async () => {
              const response = await this.$baseHttp.delete(`/goods/delete?ids=${element.id}`);
              if (!response) return;
              this.$message.success('删除成功');
              lzList.getData();
            },
          });
          break;
        case 'view':
          window.open(`${config.giftUrl}/details/${element.id}`);
          // this.$refs.preview.init(element.id);
          break;
        case 'order-view':
          this.$refs.orderView.init(element.id, element.title);
          break;
        case 'appraise-view':
          this.$refs.appraiseView.init(element.id, element.title);
          break;
        case 'add':
        case 'edit': {
          const name = type === 'add' ? 'create' : 'view';
          this.$router.push({
            name: `${this.$route.name}-${name}`,
            query: {
              id: element ? element.id : '',
              ...this.$route.query,
            },
          });
          break;
        }
        case 'export': {
          const query = getSearchValue(this.search);
          query.is_export = 1;
          query.token = getAccessToken();
          delete query.page;
          delete query.page_size;
          window.open(`${config.api}/goods/list?${this.$jsonToUrl(query)}`);
        }
          break;
        case 'ppt':
          this.$refs.exportPpt.init();
          break;
        default:
          this.$message.warning('事件还未处理');
          break;
      }
    },
    handleSwitchChange(type, row) {
      this.$baseHttp.post('/goods/action', {
        type,
        ids: row.id,
        value: row[`is_${type}`],
      }).then((response) => {
        if (!response) {
          this.$set(row, `is_${type}`, Number(!row[`is_${type}`]));
          return;
        }
        this.$message.success('操作成功');
      });
    },
    handleCheckboxChange(values) {
      this.values = values.map((r) => ({
        ...r,
        htmlButton: [{ name: '删除', key: 'delete', type: 'danger' }],
      }));
    },
    hanldeAddPPt() {
      this.$refs.exportPpt.handleAddProduct(this.values);
    },
    searchFomate(params) {
      if (params.productFomate === 'is_charge') {
        this.$set(params, 'is_charge', 1);
      } else if (params.productFomate === 'is_virtual') {
        this.$set(params, 'is_virtual', 1);
      } else if (params.productFomate === 'is_normal') {
        this.$set(params, 'is_charge', 0);
        this.$set(params, 'is_virtual', 0);
      }
      this.$delete(params.productFomate);
      return params;
    },
  },
};
</script>
<style lang="scss" scoped></style>
