<template>
  <lz-dialog
    :title="title"
    :is-close-btn="false"
    ref="lzDialog">
    <lz-list
      :config="config"
      :table="table"
      :is-router="false"
      :search="search"
      ref="lzList">
    </lz-list>
  </lz-dialog>
</template>
<script>
export default {
  data() {
    return {
      title: '',
      search: [
        { name: '产品id', fieldName: 'goods_id', value: '' },
      ],
      table: [
        { name: '服务评价', prop: 'service_star' },
        { name: '快递评价', prop: 'express_star' },
        { name: '产品评价', prop: 'goods_star' },
        { name: '备注', prop: 'memo' },
        { name: '评价时间', prop: 'create_time' },
      ],
      config: {
        url: '/comment/list',
      },
    };
  },
  methods: {
    init(id, title) {
      this.search[0].value = id;
      this.title = title;
      this.$refs.lzDialog.dialogVisible = true;
      this.$nextTick(() => {
        this.$refs.lzList.getData();
      });
    },
  },
};
</script>
